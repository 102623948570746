/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-calendar/css/angular-calendar.css";

html,
body {
    height: 100%;
}


body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    background-color: #F8F8F8;
}


/*********************************MATERIAL INPUT********************************************/

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding-top: 1em;
    padding-bottom: 1em;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
    color: #3C3C3C;
}

.mat-mdc-form-field-appearance-outline.mat-focused .mat-mdc-form-field-outline-thick {
    color: #85C443 !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-label {
    color: #85C443 !important;
}

/* Font color */
input.mat-mdc-input-element {
    color: #212121;
}

.filters .mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline {
    color: #A6A6A629;
}

/* inicio Chat */
#md-app-widget.md-chat-widget-wrapper.LeftSide .md-chat-widget-btn-container,
#md-app-widget.md-chat-widget-wrapper.LeftSide .md-chat-widget-container {
    left: 2px !important;
}

.mat-mdc-form-field-icon-suffix {
    margin-right: 5px !important;
}

/* fim Chat */



mat-form-field {
    width: 100%;
}

.mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: #85C443 !important;
    --mdc-switch-selected-handle-color: #85C443 !important;
    --mdc-switch-selected-hover-state-layer-color: #85C443 !important;
    --mdc-switch-selected-pressed-state-layer-color: #85C443 !important;
    --mdc-switch-selected-focus-handle-color: #85C443 !important;
    --mdc-switch-selected-hover-handle-color: #85C443 !important;
    --mdc-switch-selected-pressed-handle-color: #85C443 !important;
    --mdc-switch-selected-focus-track-color: #cee7b3 !important;
    --mdc-switch-selected-hover-track-color: #cee7b3 !important;
    --mdc-switch-selected-pressed-track-color: #cee7b3 !important;
    --mdc-switch-selected-track-color: #cee7b3 !important;
}


.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: #85C443 !important;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    --mdc-theme-primary: #85C443 !important;
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
    --mdc-theme-primary: #85C443 !important;
}


.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
    background: #85C443;
}

.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-focus-icon-color: #85C443 !important;
    --mdc-checkbox-selected-hover-icon-color: #85C443 !important;
    --mdc-checkbox-selected-icon-color: #85C443 !important;
    --mdc-checkbox-selected-pressed-icon-color: #85C443 !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: #85C443;
}


button.mat-primary .mat-mdc-progress-spinner.mat-spinner-btn .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: white !important;
}

button.mat-accent .mat-mdc-progress-spinner.mat-spinner-btn .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: #85C443 !important;
}


.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic,
.loading-list .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: #85C443 !important;
}


mat-spinner.small {
    width: 20px !important;
    height: 20px !important;
    margin-left: 15px;
}

mat-spinner.small svg {
    width: 20px !important;
    height: 20px !important;
}


.outline-spinner input {
    display: inline-block;
    width: calc(100% - 12px);
}

.outline-spinner mat-spinner {
    width: 12px !important;
    height: 12px !important;
    display: inline-block;
}

.outline-spinner mat-spinner svg {
    width: 12px !important;
    height: 12px !important;
}

.outline-spinner .mat-progress-spinner circle,
.outline-spinner .mat-spinner circle {
    stroke: #426221 !important;
}


.inputSearchSelect{
    width: 100%;
    padding: 5px 15px;
    border-radius: 5px 0px 0px 0px;
    border: 2px solid #85C443;
}

.quill-input {
    /* min-height: 150px; */
    width: 100%;
    margin-bottom: 30px;
}

/*********************************END MATERIAL INPUT********************************************/

/*********************************BUTTONS********************************************/


.mdc-button {
    letter-spacing: normal;
}

.mat-mdc-unelevated-button.mat-primary.block {
    width: 100%;
}

.mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-container-color: #85C443;
    --mdc-filled-button-label-text-color: #fff;
    margin-bottom: 10px !important;
}

.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
    --mdc-fab-container-color: #85C443;
}

.btn-primary .mat-progress-spinner circle,
.btn-primary .mat-spinner circle {
    stroke: white;
}

.btn-primary mat-spinner {
    margin: auto;
    margin-top: 3px;
    margin-bottom: 3px;
}



.btn-primary.add-user {
    margin-bottom: 30px !important;
}

.btn-primary:disabled {
    background-color: #e6e6fa;
}

.btn-remove-chip {
    border: none;
    padding: 0;
}

.btn-remove-chip mat-icon {
    font-size: 18px;
    height: auto;
    width: auto;
}

.mat-mdc-unelevated-button.mat-accent {
    border: 1px solid #85C443;
    --mdc-filled-button-container-color: white;
    --mdc-filled-button-label-text-color: #85C443;
    margin-bottom: 10px !important;
}

.mat-mdc-unelevated-button.mat-accent.block {
    display: block;
    width: 100%;
}

.btn-block {
    width: 100%;
}

.back-link {
    color: #212121;
    text-decoration: none;
    display: none;
    margin-bottom: 10px;
}

.back-link:hover,
.back-link:focus {
    color: #212121;
    text-decoration: none;
}

.back-link mat-icon {
    color: #85C443;
    vertical-align: bottom;
    font-size: 20px;
}

.tooltip-flochart .mdc-tooltip__surface {
    max-width: 500px !important;
    max-height: unset !important;
}

.tooltip-discriminator .mdc-tooltip__surface {
    max-width: 500px !important;
    max-height: unset !important;
}
/*******************************************RADIO*****************************************/
.mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #85C443;
    --mdc-radio-selected-hover-icon-color: #85C443;
    --mdc-radio-selected-icon-color: #85C443;
    --mdc-radio-selected-pressed-icon-color: #85C443;
    --mat-mdc-radio-checked-ripple-color: #85C443;
}

.mat-radio-label-content {
    padding-left: 4px !important;
    padding-right: 14px !important;
}

.mat-mdc-radio-button {
    margin-bottom: 15px;
}

.copy-to-clipboard {
    color: #85C443;
    background-color: transparent;
    border: none;
    height: 21px;
    vertical-align: bottom;
}

.copy-to-clipboard:hover {
    opacity: 0.85;
}

.copy-icons {
    font-size: 16px;
}

/********************************************END RADIO************************************/


/*********************************END BUTTONS********************************************/




/*********************************LAYOUT********************************************/
.container-generic {
    display: flex;
    flex-direction: column;
}

.container-body {
    padding: 92px 20px 20px 20px;
    height: calc(100vh);
}

.container-body-secundary-header {
    padding: 195px 20px 20px 20px;
    height: calc(100vh);
}

.white-body {
    background-color: white;
    box-shadow: 0px 2px 6px #00000029;
    padding: 20px 15px;
    display: block;
    width: 100%;
    border-radius: 2px;
}

.title-header {
    border-bottom: 1px solid #C6C6C6;
    margin-bottom: 25px;
}

.title-header h1 {
    display: inline-block;
    font-size: 20px;
    vertical-align: top;
    margin-bottom: 10px;
}

.title-header mat-icon {
    display: inline-block;
    font-size: 29px;
    color: #85C443;
    margin-right: 11px;
}

.title-header mat-slide-toggle {
    height: 30px;
    margin-left: 10px;
}

.indicator{
    display: flex;
    justify-content: space-evenly;
}

/*********************************LAYOUT********************************************/


/*********************************CARD LIST********************************************/

.card-list .card-item {
    padding: 10px 9px;
    border: 1px solid #A6A6A629;
    margin-top: 15px;
    border-radius: 4px;
    height: calc(100% - 15px);
}

.card-list .card-item .infos {
    display: inline-block;
    width: calc(100% - 78px);
}

.card-list .card-item .actions {
    display: inline-block;
    width: 78px;
    text-align: right;
    padding-bottom: 4px;
    vertical-align: bottom
}

.card-list .card-item .actions a,
.card-list .card-item .actions button {
    text-align: center;
    color: #85C443;
    border: 1px solid #85C443;
    border-radius: 50px;
    width: 28px;
    height: 28px;
    display: inline-block;
    margin: 0px 5px;
    cursor: pointer;
    transition: all linear .1s;
    padding: 0px;
    background-color: white;
}

.card-list .card-item .actions a:hover,
.card-list .card-item .actions a.active,
.card-list .card-item .actions button:hover,
.card-list .card-item .actions button.active  {
    background-color: #85C443;
    color: white
}

.card-list .card-item .actions a mat-icon {
    font-size: 17px;
    padding-top: 4px;
    padding-left: 2px;
}

.card-list .card-item span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}



.card-list .card-item span.primary {
    font-size: 15px;
    font-weight: 600;
    cursor: context-menu;
}

.card-list .card-item span.secundary {
    font-size: 13px;
    cursor: context-menu;
    color: #C6C6C6
}

.card-list .card-item span.secundary-status {
    font-size: 13px;
    color: #85C443;
}

.card-list .card-item span.last-info {
    padding-top: 10px;
}

.card-list .card-item .actions.checkbox-actions span {
    overflow: unset;
}

/*********************************END CARD LIST********************************************/

/*********************************UTILS********************************************/
.vertical-aling-out {
    display: table;
    height: 100%;
    width: 100%;
}

.vertical-aling-inner {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
}

.container-out {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .2);
    transition: all linear .2s;
}

.bold {
    font-weight: bold;
}

.cdk-overlay-container {
    z-index: 99999;
}

.cdk-overlay-container mat-dialog-container {
    width: unset;
}

.tag-recurring-patient {
    width: auto;
    background-color: #FFE500;
    margin: 0 0 18px 20px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}

/*********************************END UTILS********************************************/

@media only screen and (max-width: 1000px) and (min-width: 768px) {
    .container-body-secundary-header {
        padding: 195px 20px 20px 76px;
        height: calc(100vh);
    }
}

@media only screen and (max-width: 767px) {
    .container-body {
        height: unset;
        min-height: calc(100vh);
        padding: 76px 10px 66px 10px;
        background-color: white;
    }

    .container-body-secundary-header {
        height: unset;
        min-height: calc(100vh);
        padding: 210px 10px 66px 10px;
        background-color: white;
    }

    .white-body {
        box-shadow: none;
        padding-left: 0px;
        padding-right: 2px;
    }

    .btn-primary,
    .btn-secundary {
        display: block;
        width: 100%;
    }

    .back-link {
        display: inline-block;
    }
}

/* Generic table */
.basic-table {
    width: 100%;
}

.basic-table__head {
    border-bottom: 1px solid #a6a6a680;
    color: #85C443;
    font-weight: 600;
    padding-bottom: 1rem;
}

.basic-table__head>tr>th {
    padding-bottom: 0.8rem;
}

.basic-table__head>tr>th:first-child {
    padding-left: 0.5rem;
}

.basic-table__body>tr>td:first-child {
    padding-left: 0.5rem;
}

.basic-table__body {
    color: #6e6e6e;
}

.basic-table__body>tr:hover {
    background-color: #86c4433d;
}

.basic-table__body__row {
    height: 3rem;
    border-bottom: 1px solid #A6A6A629;
}

.basic-table__body__row__action {
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    padding-right: 1rem;
    color: #A6A6A629;
}

.basic-table__body__row:hover .basic-table__body__row__action {
    color: #6e6e6e;
}

.basic-table__body__row__action>mat-icon {
    margin: 0px 5px;
}

.mobile .basic-table__body__row__action {
    color: #6e6e6e;
}

.table-content {
    overflow: auto;
}

/* Generic Accordion */

.accordion {
    display: block;
    padding-bottom: 2rem;
}

.accordion-item {
    display: block;
    border: solid 1px #A6A6A629;
    margin-bottom: 1rem;
    box-shadow: 0px 2px 6px #00000029;
}

.accordion-item+.accordion-item {
    border-top: none;
}

.accordion-item-description {
    font-size: 0.85em;
    color: #999;
}

.accordion-item-description-expanded {
    color: white;
}

.accordion-item-header {
    padding: 1rem;
}

.accordion-item-body {
    padding: 0.5rem;
}

.accordion-item:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.accordion-item:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.list-tag {
    display: block;
    margin-bottom: 15px;
}

.list-tag .tag {
    display: inline-block;
    margin-right: 8px;
    background-color: #F8F8F8;
    border-radius: 5px;
    padding: 3px 8px;
    margin-bottom: 8px;
    box-shadow: 0px 1px 1px #00000029;
}

/* Mobile config */

.mobile {
    display: none !important;
}

@media only screen and (max-width: 767px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block !important;
    }
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex-space-between {
    display: flex;
    justify-content: space-between;
}

.padding-0 {
    padding: 0.5rem;
}

.padding-y {
    padding: 0.5rem 0rem;
}

.padding-x {
    padding: 0rem 0.5rem;
}

.padding-right-0 {
    padding-right: 0.5rem;
}

/************ Material Progress Bar *************/
.mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-color: #85C443;
}

/************ END - Material Progress Bar *************/
.offline {
    display: flex;
    width: 100%;
    height: 1.5rem;
    background-color: #f0ecb5;
    text-align: center;
    color: #777777;
    top: 3.5rem;
    position: fixed;
    z-index: 99;
}

.offline>span {
    display: flex;
    align-items: center;
    margin: auto;
}

.offline--icon {
    margin-right: 1rem;
    top: 0.15rem;
    position: relative;
    font-size: 20px;
}

/************ Form Field Toggle *************/

.form-field-toggle {
    text-align: left;
    padding-bottom: 20px;
}

.form-field-toggle>mat-slide-toggle {
    display: block;
    margin-bottom: 5px;
    margin-top: 5px;
}

/************ END - Form Field Toggle *************/


/************ Beggin - Titles Default *************/
.border-green-left-title {
    border-left: 8px solid #85C443;
    padding-left: 10px;
}

/************ END - Titles Default *************/

/************ Beggin - PowerBI Size *************/
powerbi-report>div:first-of-type {
    height: 100% !important;
    width: 100% !important;
}

/************ END - PowerBI Size *************/

/******** BEGIN - TABLE BILLING ********/

.billing-cell-width {
    min-width: 140px;
}

/********* END - TABLE BILLING *********/

.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 15px;
    border-radius: 20px;
}

.custom-modalbox>mat-dialog-container {
    padding: 0 0 24px 0;
    overflow: hidden;
    width: 500px;
}

.mdc-dialog__title+.mdc-dialog__content {
    padding-top: 5px !important;
}

/******** sidenav ********/

.container-sidenav {
    position: inherit !important;
    width: 100%;
}

.mat-drawer-backdrop.mat-drawer-shown {
    position: fixed;
    z-index: 90;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


/********* floating-button *********/
.floating-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.floating-button button {
    display: flex;
    background-color: #85C443;
    color: white;
    border: none;
    border-top-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    width: 237px;
    height: 52px;
    align-items: center;
    justify-content: space-evenly;
}

.person-icon-first {
    font-size: 28px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.floating-button button:hover {
    background-color: #85C443;
}

.floating-button-icon {
    margin-right: 10px;
    font-size: 20px;
}

.floating-button-label {
    font-size: 18px;
    line-height: 22px;
}

.floating-button-close {
    font-size: 17px;
    cursor: pointer;
    display: inline-flex;
    background-color: #D9D9D9;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    position: absolute;
    color: black;
    align-items: center;
    top: -9px;
    right: -9px;
}

.floating-button-voting {
    position: absolute;
    bottom: 75px;
    right: 0px;
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 30px;
    width: 490px;
    height: 306px;
    box-shadow: -1px 1px rgba(0, 0, 0, 0.25);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.floating-button-voting-message {
    text-align: center;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
    color: #6E6E6E;
}

.floating-button-voting.visible {
    visibility: visible;
    opacity: 1;
}

.feedback-points {
    display: flex;
    margin-top: 30px;
    align-items: flex-end;
}

.feedback-point {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.feedback-point-circle {
    width: 75px;
    height: 75px;
    cursor: pointer;
    border-radius: 50%;
}

.feedback-point-circle-red {
    background-color: #ff5555;
}

.feedback-point-circle-yellow {
    background-color: #ffa500;
}

.feedback-point-circle-green {
    background-color: #33cc33;
}

.feedback-point-text {
    font-size: 14px;
    font-weight: 350;
    text-align: center;
    line-height: 22.5px;
    width: 80%;
    margin-bottom: 8px;
    color: #6E6E6E;
}

.person-icon {
    font-size: 50px;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: black;
}

.feedback-message {
    display: flex;
    flex-direction: column;
}

.feedback-point-number {
    margin-top: 20px;
    font-weight: 400;
    color: #6E6E6E;
}

.feedback-message-title {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 0px;
    color: #6E6E6E;
}

.feedback-message-sub-title {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    font-style: italic;
    color: #767676;
}

.feedback-textarea {
    width: 435px;
    height: 113px;
    border-radius: 10px;
    border-color: #919191;
    font-size: 14px;
    line-height: 21px;
    padding: 10px;
    text-align: left;
    vertical-align: top;
    font-weight: 300;
    color: #767676;
    margin-bottom: 25px;
}

.button-feedback {
    display: flex;
    justify-content: space-evenly;
}

.button-feedback-send {
    width: 163px !important;
    height: 35.76px !important;
    border-radius: 6px;
    background-color: #85C443 !important;
}

.button-feedback-jump {
    width: 163px !important;
    height: 35.76px !important;
    border-radius: 6px;
    color: #85C443 !important;
    background-color: #FFFFFF !important;
}

.thank-you-message {
    display: flex;
    flex-direction: column;
}

.thank-you-title {
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    font-weight: 600;
    color: #6E6E6E;
}

.thank-you-sub-title {
    line-height: 21px;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    color: #6E6E6E;
}

.thank-you-img {
    width: 143px;
    height: 143px;
    align-self: center;
}

/* Estilos para dispositivos móveis */
@media screen and (max-width: 600px) {

    .floating-button-label {
        font-size: 12px;
        line-height: 22px;
    }

    .floating-button-voting-message {
        font-size: 9px;
    }

    /* Reduzir o tamanho do botão flutuante */
    .floating-button button {
        width: 150px;
        height: 40px;
        font-size: 12px;
    }

    /* Ajustar o ícone da pessoa no botão flutuante */
    .person-icon-first {
        font-size: 20px;
        line-height: 20px;
    }

    /* Ajustar o tamanho do botão de fechar no botão flutuante */
    .floating-button-close {
        font-size: 14px;
        height: 16px;
        width: 16px;
        top: -7px;
        right: -7px;
    }

    /* Ajustar a posição do botão de votação */
    .floating-button-voting {
        bottom: 50px;
        right: 10px;
        padding: 20px;
        width: 300px;
        height: 200px;
    }

    /* Ajustar a posição dos círculos de feedback */
    .feedback-points {
        margin-top: 10px;
        justify-content: space-between;
    }

    /* Ajustar o tamanho do ícone de pessoa nos círculos de feedback */
    .person-icon {
        font-size: 30px;
    }

    /* Ajustar o tamanho do título na janela de feedback */
    .feedback-message-title {
        font-size: 10px;
    }

    /* Ajustar o tamanho do sub-título na janela de feedback */
    .feedback-message-sub-title {
        font-size: 7px;
        font-weight: 400;
    }

    /* Ajustar o tamanho da caixa de texto de feedback */
    .feedback-textarea {
        width: 250px;
        height: 80px;
        font-size: 9px;
        margin-bottom: 10px;
    }

    /* Ajustar o tamanho dos botões de feedback */
    .button-feedback-send,
    .button-feedback-jump {
        width: 100px !important;
        height: 20.88px !important;
        font-size: 10px;
    }

    .feedback-point-number {
        margin-top: 10px;
    }

    .feedback-point-circle {
        width: 50px;
        height: 50px;
    }

    .feedback-point-text {
        font-size: 8px;
        font-weight: 400;
        text-align: center;
        line-height: 12.5px;
        margin-bottom: 4px;
        color: #6E6E6E;
    }

    .thank-you-title {
        font-size: 14px;
        margin-bottom: 0px;
    }

    .thank-you-img {
        width: 92px;
        height: 92px;
        align-self: center;
    }

    .thank-you-sub-title {
        font-size: 10px;
    }
}
/********* end floating-button *********/

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #85C443;
    color: #85C443;
    animation: dot-flashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
.dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}
.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #85C443;
    color: #85C443;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
}
.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #85C443;
    color: #85C443;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
}
  
@keyframes dot-flashing {
    0% {
      background-color: #85C443;
    }
    50%, 100% {
      background-color: rgba(152, 128, 255, 0.2);
    }
}

  /**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #85C443;
    color: #85C443;
    box-shadow: 9999px 0 0 -5px;
    animation: dot-pulse 1.5s infinite linear;
    animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #85C443;
    color: #85C443;
}
.dot-pulse::before {
    box-shadow: 9984px 0 0 -5px;
    animation: dot-pulse-before 1.5s infinite linear;
    animation-delay: 0s;
}
.dot-pulse::after {
    box-shadow: 10014px 0 0 -5px;
    animation: dot-pulse-after 1.5s infinite linear;
    animation-delay: 0.5s;
}

.queue-selected-patient .basic-table__body__row__action{
    color: #6e6e6e;
}

.queue-selected-patient {
    background-color: #86c4433d;
}

.queue-priority-icon {
    display: inline-flex;
    justify-content: center;
    width: 24px;
    height: 24px;
}
  
@keyframes dot-pulse-before {
    0% {
      box-shadow: 9984px 0 0 -5px;
    }
    30% {
      box-shadow: 9984px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 9984px 0 0 -5px;
    }
}
@keyframes dot-pulse {
    0% {
      box-shadow: 9999px 0 0 -5px;
    }
    30% {
      box-shadow: 9999px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 9999px 0 0 -5px;
    }
}
@keyframes dot-pulse-after {
    0% {
      box-shadow: 10014px 0 0 -5px;
    }
    30% {
      box-shadow: 10014px 0 0 2px;
    }
    60%, 100% {
      box-shadow: 10014px 0 0 -5px;
    }
}

.stage {
    display: flex;
    justify-content: center;
}


/* .mat-tab-label, .mat-tab-label.mat-tab-label-active,
.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #85C443;
} */

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, 
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #85C443;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, 
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: #85C443;
}

.centralized-content {
    text-align: center;
    justify-items: center;
}